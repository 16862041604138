
export const officialDataCCAA = {
    title: "Fuentes de datos oficiales",
    slug: "official-data-sources-ccaa",
    sections: [
        { 
            title: "Comunidades",
            cards: [
                {
                    title: "Junta de Andalucía",
                    name: "Andalucía",
                    slug: "es-an",
                    isocode: "es-an",
                    sources : [
                        {
                            title: "Website Referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Página web informativa del coronavirus",
                                    link: "//www.juntadeandalucia.es/organismos/saludyfamilias/areas/salud-vida/paginas/Nuevo_Coronavirus.html"
                                }
                            ]
                        },
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Dashboard de evolución por provincias",
                                    link: "//www.juntadeandalucia.es/institutodeestadisticaycartografia/salud/COVID19.html"
                                }
                            ]
                        },
                        {
                            title: "Datasets",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Datasets",
                                    link: "//www.juntadeandalucia.es/institutodeestadisticaycartografia/badea/informe/anual?CodOper=b3_2314&idNode=42348"
                                }
                            ]
                        },
                        {
                            title: "Listado",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Listado de comunicados oficiales con cifras por provincia",
                                    link: "//www.juntadeandalucia.es/organismos/saludyfamilias/areas/salud-vida/paginas/coronavirus-comunicados-anteriores.html"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Gobierno de Aragón",
                    name: "Aragón",
                    slug: 'es-ar',
                    isocode: "es-ar",
                    sources : [
                        {
                            title: "Website Referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Website informativo del coronavirus",
                                    link: "//www.aragon.es/-/informacion-sobre-el-coronavirus"
                                },
                                {
                                    icon: "link",
                                    name: "Website salud pública",
                                    link: "http://aragonhoy.net/index.php/mod.noticias/mem.detalle/area.1050/relmenu.4/id.258743"
                                }
                            ]
                        },
                        {
                            title: "Sala de Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Aragón Hoy - Sala de prensa - noticias covid-19",
                                    link: "//aragonhoy.net/index.php/mod.portadas/mem.detalle/id.229"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Gobierno del Principado de Asturias",
                    name: "Asturias",
                    slug: 'es-as',
                    isocode: "es-as",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                    icon: "link",
                                    name: "Observatorio de Salud de Asturas - Información covid-19 en Asturias",
                                    link: "//obsaludasturias.com/obsa/informacion-covid-19-en-asturias/"
                                }
                            ]
                        },
                        {
                            title: "Dashboards",
                            links: [
                                {
                                    icon: "link",
                                    name: "Datos abiertos - Dashboard de evolución de datos",
                                    link: "//app.transparenciaendatos.es/v/#!/5e8dee9d26e3017b3da65ba8"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Gobierno de Cantabria",
                    name: "Cantabria",
                    slug: 'es-cb',
                    isocode: "es-cb",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                    icon: "link",
                                    name: "Consejería de Salud - Página web dedicada al coronavirus (con datos abiertos)",
                                    link: "//www.scsalud.es/coronavirus"
                                }
                            ]
                        },
                        {
                            title: "Sala de prensa",
                            links: [
                                {
                                    icon: "link",
                                    name: "Gobierno de Cantabria - Notas de prensa",
                                    link: "//www.cantabria.es/web/comunicados"
                                }
                            ]
                        },
                        {
                            title: "Dashboards",
                            links: [
                                {
                                    icon: "link",
                                    name: "Dashboard de visualización covid-19",
                                    link: "//www.scsalud.es/coronavirus"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Ciudad Autónoma de Ceuta",
                    name: "Ceuta",
                    slug: 'es-ce',
                    isocode: "es-ce",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [ 
                                {
                                    icon: "link",
                                    name: "Sin datos",
                                    link: ""
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Junta de Castilla y León",
                    name: "Castilla y León",
                    slug: 'es-cl',
                    isocode: "es-cl",
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Dashboard de la situación actual",
                                    link: "//analisis.datosabiertos.jcyl.es/pages/coronavirus/situacin-actual#situacin-actual"
                                }
                            ]
                        },
                        {
                            title: "Datasets",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Datasets relacionados con el covid-19",
                                    link: "//analisis.datosabiertos.jcyl.es/explore/?sort=modified&q=covid"
                                }
                            ]
                        },
                        {
                            title: "API",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Link API abierta de consultas",
                                    link: "//analisis.datosabiertos.jcyl.es/api/v1/console/datasets/1.0/search/"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Junta de Castilla La Mancha",
                    name: "Castilla La Mancha",
                    slug: 'es-cm',
                    isocode: "es-cm",
                    sources : [
                        {
                            title: "Website Referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Página web informativa del coronavirus",
                                    link: "//sanidad.castillalamancha.es/ciudadanos/enfermedades-infecciosas/coronavirus"
                                },
                                {
                                    icon: "link",
                                    name: "Sala de prensa - comunicados oficiales con cifras por provincia (Es necesario filtrar)",
                                    link: "//sanidad.castillalamancha.es/notas-de-prensa"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Gobierno de Canarias",
                    name: "Canarias",
                    slug: 'es-cn',
                    isocode: "es-cn",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                    icon: "link",
                                    name: "Website del Servicio Canario de Salud - Sección coronavirus",
                                    link: "//www3.gobiernodecanarias.org/sanidad/scs/tematica.jsp?idCarpeta=e01092c2-7d66-11ea-871d-cb574c2473a4"
                                }
                            ]
                        },
                        {
                            title: "Dashboards",
                            links: [
                                {
                                    icon: "link",
                                    name: "Dashboard y mapa de evolución de la Consejería de Salud",
                                    link: "//grafcan1.maps.arcgis.com/apps/opsdashboard/index.html#/156eddd4d6fa4ff1987468d1fd70efb6"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Generalitat de Catalunya",
                    name: "Cataluña",
                    slug: 'es-ct',
                    isocode: "es-ct",
                    sources : [
                        {
                            title: "Website Referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Website informativo del coronavirus",
                                    link: "//canalsalut.gencat.cat/ca/salut-a-z/c/coronavirus-2019-ncov/index.html#googtrans(ca%7Ces)"
                                },
                                {
                                    icon: "link",
                                    name: "Portal de datos abiertos",
                                    link: "//analisi.transparenciacatalunya.cat/en/browse?q=covid&sortBy=relevance"
                                }
                            ]
                        },
                        {
                            title: "Mapa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Sección de acceso a mapas y visualizaciones de datos",
                                    link: "//aquas.gencat.cat/ca/actualitat/ultimes-dades-coronavirus"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Junta de Extremadura",
                    name: "Extremadura",
                    slug: 'es-ex',
                    isocode: "es-ex",
                    sources : [
                        {
                            title: "Website referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Website de la Junta de Extremadura",
                                    link: "//www.juntaex.es/web/"
                                },
                                {
                                    icon: "link",
                                    name: "Hemeroteca de la sala de prensa",
                                    link: "//www.juntaex.es/comunicacion/hemeroteca"
                                }
                            ]
                        },
                    ]
                },
                {
                    title: "Xunta de Galicia",
                    name: "Galicia",
                    slug: 'es-ga',
                    isocode: "es-ga",
                    sources : [
                        {
                            title: "Website Referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Website informativo del coronavirus",
                                    link: "//www.sergas.es/Saude-publica/Web-coronavirus"
                                }
                            ]
                        },
                        {
                            title: "Sala de prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Sala de prensa del departamento de salud",
                                    link: "//saladecomunicacion.sergas.gal/Paginas/ListaxeNovas.aspx?idioma=es"
                                }
                            ]
                        }
                        
                    ]
                },
                {
                    title: "Govern Illes Balears",
                    name: "Illes Balears",
                    slug: 'es-ib',
                    isocode: "es-ib",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                    icon: "link",
                                    name: "Website de la Consejería de Salud - Sección coronavirus",
                                    link: "http://www.caib.es/sites/coronavirus/es/portada/"
                                }
                            ]
                        },
                        {
                            title: "Dashboard",
                            links: [
                                {
                                    icon: "link",
                                    name: "Dashboard y mapa de la evolución de la Consejería de Salud",
                                    link: "https://grafcan1.maps.arcgis.com/apps/opsdashboard/index.html#/156eddd4d6fa4ff1987468d1fd70efb6"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Región de Murcia",
                    name: "Región de Murcia",
                    slug: 'es-mc',
                    isocode: "es-mc",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                    icon: "link",
                                    name: "Website de Murcia Salud",
                                    link: "https://www.murciasalud.es/pagina.php?id=455585"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Comunidad de Madrid",
                    name: "Comunidad de Madrid",
                    slug: 'es-md',
                    isocode: "es-md",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                    icon: "link",
                                    name: "Sitio informativo. Acceso a informes e histórico de notas de prensa",
                                    link: "//www.comunidad.madrid/servicios/salud/2019-nuevo-coronavirus"
                                }
                            ]
                        },
                        {
                            title: "Mapas",
                            links: [
                                {
                                    icon: "link",
                                    name: "Mapa de situación por municipios",
                                    link: "//comunidadmadrid.maps.arcgis.com/apps/PublicInformation/index.html?appid=cdfb61b3eb3a49c2b990b4fdb41dfcfe"
                                },
                                {
                                    icon: "link",
                                    name: "Mapa de situación por área de salud",
                                    link: "//comunidadmadrid.maps.arcgis.com/apps/PublicInformation/index.html?appid=7db220dc2e0a40b4a928df661a89762e"
                                }
                            ]
                        },
                        {
                            title: "Datasets",
                            links: [
                                {
                                    icon: "link",
                                    name: "Datos abiertos - Datasets Covid-19",
                                    link: "//datos.comunidad.madrid/catalogo/organization/comunidad-de-madrid?q=covid&sort=score+desc%2C+metadata_modified+desc"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Ciudad Autónoma de Melilla",
                    name: "Melilla",
                    slug: 'es-ml',
                    isocode: "es-ml",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                icon: "link",
                                name: "Sin datos",
                                link: ""
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Gobierno de Navarra",
                    name: "Navarra",
                    slug: 'es-na',
                    isocode: "es-na",
                    sources: [
                        {
                            title: "Sala de prensa",
                            links: [
                                {
                                    icon: "link",
                                    name: "Gobierno de Navarra - Notas de prensa",
                                    link: "//www.navarra.es/home_es/Gobierno+de+Navarra/Organigrama/Los+departamentos/Salud/Organigrama/Estructura+Organica/Instituto+Navarro+de+Salud+Publica/Novedades/notas_prensa.htm"
                                }
                            ]
                        },
                        {
                            title: "Datasets",
                            links: [
                                {
                                    icon: "link",
                                    name: "Gobierno abierto - Descarga de datos del coronavirus",
                                    link: "//gobiernoabierto.navarra.es/es/coronavirus"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Euskadi",
                    name: "Euskadi",
                    slug: 'es-pv',
                    isocode: "es-pv",
                    sources : [
                        {
                            title: "Mapa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Website del departamento de Salud - Mapa interactivo de datos",
                                    link: "//www.geo.euskadi.eus/covid-19-euskadi/s69-geodir/es/"
                                }
                            ]
                        },
                        {
                            title: "Website Referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Website del departamento de Salud - Informes de actualización datos",
                                    link: "//www.euskadi.eus/boletin-de-datos-sobre-la-evolucion-del-coronavirus/web01-a2korona/es/"
                                }
                            ]
                        },
                        {
                            title: "Open Data",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Open Data Euskadi - Evolución del coronavirus (COVID-19) en Euskadi",
                                    link: "//opendata.euskadi.eus/catalogo/-/evolucion-del-coronavirus-covid-19-en-euskadi/"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Gobierno de La Rioja",
                    name: "La Rioja",
                    slug: 'es-ri',
                    isocode: "es-ri",
                    sources: [
                        {
                            title: "Website de referencia",
                            links: [
                                {
                                    icon: "link",
                                    name: "Gobierno de La Rioja - Sección y datos coronavirus",
                                    link: "//actualidad.larioja.org/coronavirus/datos"
                                },
                                {
                                    icon: "link",
                                    name: "Website de Rioja Salud",
                                    link: "//www.riojasalud.es/profesionales/epidemiologia/alertas-epidemiologicas/neumonia-por-nuevo-coronavirus-ncov-en-china"
                                }
                            ]   
                        }
                    ]
                },
                {
                    title: "Comunitat Valenciana",
                    name: "Valencia",
                    slug: 'es-vc',
                    isocode: "es-vc",
                    sources : [
                        {
                            title: "Website Referencia",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Website informativo del coronavirus",
                                    link: "//coronavirus.san.gva.es/es/inicio"
                                }
                            ]
                        },
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Dashboard de monitorización de la situación",
                                    link: "//coronavirus.san.gva.es/es/estadisticas"
                                }
                            ]
                        },
                        {
                            title: "Sala de Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Sala de prensa - comunicados oficiales con cifras por provincia",
                                    link: "//www.gva.es/va/inicio/area_de_prensa/ap_notas_prensa?tipoContenido=26&zona=21&botonBuscar=buscar&busquedaorganismo=3.07"
                                }
                            ]
                        }
                    ]
                },  
            ]
        }
    ]
}
