import { Box, Stack, Text, Inline } from "@sqymagma/elements"
import React, {useState, useEffect} from "react"
import IconSystem from "../IconSystem"
import { theme } from "../../utils/themeGet"
import Img from "gatsby-image" 
import { useStaticQuery, graphql } from "gatsby";


const Card = ({ children, title, sources, slug, subtitle, dataSet, ...props }) => {

    const imageData = useStaticQuery(graphql`
      query {
          cardsImages: allFile(filter: {relativeDirectory: {eq: "resources-cards"}}) {
            nodes {
              name
              childImageSharp {
                fluid(quality: 90, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64

                }
              }
            }
          }
      }
    `)

    const [ officialSourceImg, setOfficialSourceImg ] = useState(null)
    useEffect(() => {
      setOfficialSourceImg(imageData.cardsImages.nodes.find(el => el.name === slug))
    }, [imageData, slug])


  const { mx } = props;
  return (
    <Stack
      width={1}
      height="100%"
      bg="ui07"
      flexDirection={"column"}
      mb="m"
      mx={mx}
    >
      {officialSourceImg && dataSet === 'official-data-sources-es' && 
        <Box>
          <Img fluid={officialSourceImg.childImageSharp.fluid}/>
        </Box>
      }
      <Box p="s" bg="ui01">
        <Inline alignItems="center">
            <Box width="120px" mr="s" css={`{border: 1px solid ${theme("color.ui04")}}`}>
                <img src={`/images/svg/resources-logos/${slug}.svg`} alt={slug}/>
            </Box>
            <Box flex={1}>
                <Text as="p" textStyle="l" color="text01" maxWidth="300px">{title}</Text>
                {subtitle && <Text as="p" textStyle="l" color="text05" maxWidth="300px">{subtitle}</Text>}
            </Box>
        </Inline>
      </Box>

      <Box bg="ui07" px="s" pt="12px">
        {sources && sources.map(source => (
          <Box mb="s">
            <Text as="h3" textStyle="m-semi" color="brand03" mb="4px">{source.title}</Text>

              {source.links.map((item,idx) => (
                  <a href={item.link} target="_blank" key={idx} rel="noopener noreferrer">
                    <Inline alignItems="flex-start" mb="4px" flexWrap="no-wrap">
                      <IconSystem name={item.icon} height="16px" fill="ui04" mr="8px"/>
                      <Text as="h3" textStyle="smallBodyText" color="text06">{item.name}</Text>
                    </Inline>
                  </a>
                
              ))}
          </Box>
        ))}
      </Box>

    </Stack>
  )
}
export default Card
