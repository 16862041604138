import React from "react";
import { officialDataES, sourcesOfData } from "../data/resourcesData";
import Intro from "../components/Resources/Intro";
import SourcesCards from "../components/Resources/SourcesCards";
import SourcesCCAA from "../components/Resources/SourcesCCAA";
import SEO from "../components/Seo";

const IndexPage = () => {
  return (
    <>
      <SEO
        title={`Recursos`}
        description={`Recursos informativos sobre la pandemia del coronavirus en España`}
        favicon={`/images/open-graph-image-green.png`}
        image={`/images/open-graph-image-green.png`}
        keywords={`resources`}
      />
      <Intro />
      <SourcesCards data={officialDataES} />
      <SourcesCCAA />
      {sourcesOfData.map((type, idx) => (
        <SourcesCards data={type} idx={idx} key={idx} />
      ))}
    </>
  );
};

export default IndexPage;
