import React, { useState } from "react"
import { MaxWidth, Box, Flex } from "@sqymagma/elements"
import Section from "../Section"
// import SpainMap from "./SpainMapDynamicSvg"
import SpainMap from "./SpainMapStaticSvg"
import { officialDataCCAA } from "../../data/resourcesDataCCAA"
import CCAACarousel from "./CCAACarousel"
import CCAAList from "./CCAAList"

export default () => {
const [{ cards }] = officialDataCCAA.sections
const [hoveredCard, setHoveredCard] = useState(null)
const [clickedCard, setClickedCard] = useState(null)
const [isOpen, setisOpen] = useState(false)

const toggleCarousel = () => setisOpen(!isOpen)
const handleMouseOverListItem = ccaa => setHoveredCard(ccaa.isocode)

const handleMouseOutListItem = _ => {
    setHoveredCard(null)
}

const handleClickedListItem = ccaa => {
    setClickedCard(ccaa)
    setisOpen(!isOpen)
}

return (
    <Flex bg="ui07" width={1} alignItems="center" justifyContent="center" position="relative"
        py={[0, null, "m", "xl"]}
    >
    <Section >
        <MaxWidth height="100%">
            <CCAACarousel
                cards={cards}
                card={clickedCard}
                isOpen={isOpen}
                toggleCarousel={toggleCarousel}
            />
            <Flex flexWrap="wrap" overflow="hidden" alignItems="start">
                <Box
                    width={[1, null, 7 / 12]}
                    pr={[0, null, "l"]}
                    position="relative"
                    css={` { svg { width: 100%; height: 100%;} }`}
                >
                    <SpainMap data={cards} hoveredCard={hoveredCard} handleClicked={(ccaa) => handleClickedListItem(ccaa)}/>
                </Box>
                <Flex width={[1, null, 5 / 12]} alignItems="center" py="m" justifyContent="center">
                    <CCAAList 
                        cards={cards} 
                        handleMouseOver={(ccaa) => handleMouseOverListItem(ccaa)}
                        handleMouseOut={() => handleMouseOutListItem()}
                        handleClicked={(ccaa) => handleClickedListItem(ccaa)}
                    />
                </Flex>
            </Flex>
        </MaxWidth>
    </Section>
    </Flex>
)}

