
export const officialDataES = {
    title: "Fuentes de datos oficiales",
    slug: "official-data-sources-es",
    sections: [
        { 
            title: "Internacionales",
            cards: [
                {
                    title: "WHO - OMS",
                    subtitle: "World Health Organization. Organización Mundial de la Salud",
                    slug: "who-oms",
                    sources : [
                        {
                        title: "Website Map and Situation Reports",
                        links:  [
                                {
                                    icon: "file",
                                    name: "Coronavirus (COVID-19) Map",
                                    link: "//"
                                },
                                {
                                    icon: "file",
                                    name: "Coronavirus diseiase (COVID-19) Situation Report",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                title: "ECDC",
                subtitle: "Centro Europeo para la Prevención y Control de Enfermedades",
                slug: 'ecdc',
                sources : [
                    {
                        title: "Tablas de datos",
                        links:  [
                                {
                                    icon: "file",
                                    name: "Informe de situación europea",
                                    link: "//"
                                },
                                {
                                    icon: "file",
                                    name: "Informe de situación mundial",
                                    link: "//"
                                },
                                {
                                    icon: "download",
                                    name: "Descargar la tabla de datos del día",
                                    link: "//"
                                }
                        ]
                    }
                ]
            },
            ]
        },
        { 
            title: "Nacionales",
            cards: [
                {
                    title: "Ministerio de Sanidad ES",
                    subtitle: "Ministerio de Sanidad de España",
                    slug: "ministerio-sanidad-es",
                    sources : [
                        {
                        title: "Website Referencia",
                        links:  [
                                {
                                    icon: "link",
                                    name: "Página informativa de la situación actual",
                                    link: "//"
                                },
                                {
                                    icon: "link",
                                    name: "Informes oficiales de seguimiento del Ministerio Sanidad",
                                    link: "//"
                                },
                                {
                                    icon: "link",
                                    name: "Informes oficiales de seguimiento del Ministerio Sanidad",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                title: "Departamento de Seguridad Nacional",
                subtitle: "Presidencia del Gobierno",
                slug: 'seguridad-nacional',
                sources : [
                    {
                        title: "Website Referencia",
                        links:  [
                            {
                                icon: "link",
                                name: "Sala de Prensa - situación actual",
                                link: "//"
                            },
                        ]
                    }
                ]
            },
            ]
        }
    ]
}


const official_data_sources_INT = {
    title: "Fuentes de datos oficiales internacionales",
    slug: "offical-data-sources-int",
    sections: [
        { 
            title: "Italia",
            cards: [
                {
                    title: "Ministerio de sanidad",
                    slug: "ministerio-sanidad-it",
                    sources : [
                        {
                        title: "Website Referencia",
                        links:  [
                                {
                                    icon: "link",
                                    name: "Website - sección coronavirus",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                title: "Protección Civil",
                slug: 'proteccion-civil-it',
                sources : [
                    {
                        title: "Dashboard",
                        links:  [
                            {
                                icon: "link",
                                name: "Dashoard del Departamento de Protección Civil",
                                link: "//"
                            },
                        ]
                    }
                ]
            },
            ]
        },
        { 
            title: "Francia",
            cards: [
                {
                    title: "Ministerio de Sanidad",
                    slug: "ministerio-sanidad-fr",
                    sources : [
                        {
                        title: "Website Referencia",
                        links:  [
                            {
                                icon: "link",
                                name: "Página informativa de la situación actual",
                                link: "//"
                            },
                            ],
                        },
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Dashboard oficial del gobierno",
                                    link: "//"
                                },
                            ],
                        }
                    ]
                },
                {
                title: "Open Data",
                slug: 'open-data-fr',
                sources : [
                    {
                        title: "Open Data",
                        links:  [
                            {
                                icon: "link",
                                name: "Open Data Oficial - Datos COVID19",
                                link: "//"
                            },
                        ]
                    }
                ]
            },
            ]
        },
        { 
            title: "Reino Unido",
            subtitle: "Gobierno de UK",
            cards: [
                {
                    title: "GOV.UK",
                    slug: "gov-uk",
                    sources : [
                        {
                        title: "Website Oficial",
                        links:  [
                            {
                                icon: "link",
                                name: "Website oficial sobre el coronavirus",
                                link: "//"
                            },
                            ],
                        },
                        {
                            title: "Ayuda Ciudadanos",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Página de ayuda a ciudadanos",
                                    link: "//"
                                },
                            ],
                        },
                        {
                            title: "Open Negocios",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Open Negocios",
                                    link: "//"
                                },
                            ],
                        }
                    ]
                },
            ]
        }
    ]
}
    
       
 
const apis_repos = {
    title: "APIs & Repos",
    slug: "apis-repos",
    sections: [
        { 
            cards: [
                {
                    title: "Secuoyas - COVID 19",
                    slug: "secuoyas-covid",
                    sources : [
                        {
                        title: "API",
                        links:  [
                                {
                                    icon: "link",
                                    name: "Secuoyas - covid19.secuoyas.io/api",
                                    link: "//covid19.secuoyas.io/api"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "The Virustracker",
                    slug: 'virustracker',
                    sources : [
                        {
                            title: "API",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Virustracker - https://thevirustracker.com/api",
                                    link: "//thevirustracker.com/api"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "John Hopkins University",
                    slug: 'john-hopkins-university',
                    sources : [
                        {
                            title: "API",
                            links:  [
                                {
                                    icon: "link",
                                    name: "John Hopkins University - https://github.com/CSSEGISandData/COVID-19",
                                    link: "//github.com/CSSEGISandData/COVID-19"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Datadista",
                    slug: 'datadista',
                    sources : [
                        {
                            title: "Datadista",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Datadista - https://github.com/datadista",
                                    link: "//github.com/datadista"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Narrativa",
                    slug: 'narrativa',
                    sources : [
                        {
                            title: "Tracking",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Narrativa - https://covid19tracking.narrativa.com/",
                                    link: "//covid19tracking.narrativa.com/"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Tinybird",
                    slug: 'tinybird',
                    sources : [
                        {
                            title: "Datadista",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Tinybird - sobre datos de datadista",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Claudio Heidel",
                    slug: 'claudio-heidel',
                    sources : [
                        {
                            title: "API",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Claudio Heidel - API sobre datos de Secuoyas",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Carl Aiau",
                    slug: 'github',
                    sources : [
                        {
                            title: "Datadista",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Carl Aiau - Flatten the curve",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Numeroteca",
                    slug: 'numeroteca',
                    sources : [
                        {
                            title: "API",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Numeroteca - covid19",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Ibersora",
                    slug: 'github',
                    sources : [
                        {
                            title: "Repo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Ibersora - covid-19-data",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
            ]
        }
    ]
}
          

const trackersInteractiveMaps = {
    title: "Trackers",
    slug: "trackers-interactive-maps",
    sections: [
        {
            title: "Mapas interactivos",
            cards: [
                {
                    title: "Bing Covid-19",
                    slug: "bing",
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Bing - Microsoft",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "VAC",
                    slug: 'vac',
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "VAC - London School of Hygiene",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "John Hopkins University",
                    slug: 'john-hopkins-university',
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "John Hopkins University - CSSE",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "University of Washington",
                    slug: 'washington-university',
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "University of Washington",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Kaiser Family Foundation",
                    slug: 'kaiser-family-foundation',
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Kaiser Family Foundation",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Nikkei Asian Review",
                    slug: 'nikkei-assian-review',
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Nikkei Asian Review",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "WorldOMeter",
                    slug: 'world-o-meter',
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "WorldOMeter",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Google COVID-19 Community Mobility Report",
                    slug: 'google',
                    sources : [
                        {
                            title: "Mapa interactivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Google COVID-19 Community Mobility Report",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}
       
const trackersCuriosities = {
    title: "Trackers",
    slug: "trackers-curiosities",
    sections: [
        {
            title: "Curiosidades",
            cards: [
                {
                    title: "Hadouken.cn",
                    slug: "hadouken",
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Hadouken.cn - Dashboard con Google Data Studio con datos de JHU",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Avi Shiffmann",
                    slug: 'avi-shiffman',
                    sources : [
                        {
                            title: "Dahsboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Avi Shiffmann - Dashboard y tablas bootstapeado",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Icao",
                    slug: 'icao',
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Icao - 3D visualization",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Corona-cli",
                    slug: 'github',
                    sources : [
                        {
                            title: "Dahsboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Corona-cli - Coronavirus tracker in the command line",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
            ]
        }
    ]
}

const predictiveModels = {
    title: "Modelos predictivos",
    slug: "modelos-predictivos",
    sections: [
        {
            cards: [
                {
                    title: "ECDC",
                    slug: "ecdc",
                    sources : [
                        {
                            title: "Algoritmo predictivo",
                            links:  [
                                {
                                    icon: "link",
                                    name: "ECDC - Algoritmo predictivo",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Universitat Rovira y Virgili",
                    slug: 'universitat-rovira-virgili',
                    sources : [
                        {
                            title: "Dahsboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Universitat Rovira i Virgili - Mapa de Riesgo",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}


const commercialTools = {
    title: "Herramientas comerciales",
    slug: "herramientas-comerciales",
    sections: [
        {
            cards: [
                {
                    title: "ESRI",
                    slug: "esri",
                    sources : [
                        {
                            title: "Mapa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "ESRI - Mapping the novel coronavirus outbreak",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "DOMO",
                    slug: 'domo',
                    sources : [
                        {
                            title: "App",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Domo - Coronavirus Tracking",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Tableau",
                    slug: "tableau",
                    sources : [
                        {
                            title: "App",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Tableau - Access and analyze trusted COVID-19 (Coronavirus) global data",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Here",
                    slug: "here",
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Here - Coronavirus",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Mapbox",
                    slug: "mapbox",
                    sources : [
                        {
                            title: "App",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Mapbox - Covid-19",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Earth3DMap",
                    slug: "earth-3d-map",
                    sources : [
                        {
                            title: "Mapa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Earth3DMap - Coronavirus",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Nextstrain",
                    slug: "nextstrain",
                    sources : [
                        {
                            title: "App",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Nextstrain - Novel coronavirus (2019-nCoV)",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    ]
}



const livefeedNews = {
    title: "Livefeed Noticias",
    slug: "livefeed-noticias",
    sections: [
        {
            cards: [
                {
                    title: "Ministerio Sanidad Pública",
                    slug: "ministerio-sanidad-es",
                    sources : [
                        {
                            title: "Livefeed",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Twitter Ministerio Sanidad Público",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Redacción Médica",
                    slug: 'redaccion-medica',
                    sources : [
                        {
                            title: "Livefeed",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Redacción Médica",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}



const newsArticles = {
    title: "Artículos",
    slug: "articulos",
    sections: [
        {
            title: "Prensa",
            cards: [
                {
                    title: "South China Morning Post",
                    slug: "south-china-morning-post",
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "South China Morning Post - Coronavirus: the new disease Covid-19",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "El País",
                    slug: 'el-pais',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "El País - Así evoluciona la curva del coronavirus en España",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Bloomberg",
                    slug: 'bloomberg',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Bloomberg - Mapping the Coronavirus Outbreak Across the World",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Washington Post",
                    slug: 'washington-post',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Washington Post - How epidemics like covid-19 end",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "The New York Times",
                    slug: 'new-york-times',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "New York Times - Could Coronavirus Cause as Many Deaths as Cancer in the U.S.? Putting Estimates in Context",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "Visual Capitalist",
                    slug: 'visual-capitalist',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Visual Capitalist - History of pandemics",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "The New York Times",
                    slug: 'new-york-times',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "New York Times - How The Virus Got Out",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "The Financial Times",
                    slug: 'financial-times',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "The Financial Times - Coronavirus tracked: the latest figures as the pandemic spreads",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "RTVE",
                    slug: 'rtve',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "RTVE - El mapa del coronavirus: más de 170.000 casos en 150 países",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "Il Sole 24hrs",
                    slug: 'il-sole-24',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "IlSole 24hrs - 24Lab-coronavirus",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "Our World in Data",
                    slug: 'our-world-in-data',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Our World in Data - Coronavirus Disease (COVID-19)",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "Vox",
                    slug: 'vox',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Vox - 11 charts that explain the coronavirus pandemic",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }, {
                    title: "Corona Reader",
                    slug: 'corona-reader',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Más artículos en el coronareader",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
            ]
        }
    ]
}


const academicArticles = {
    title: "Artículos",
    slug: "articulos",
    sections: [
        {
            title: "Académicos",
            cards: [
                {
                    title: "The Hammer and the Dance",
                    slug: "medium",
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Coronavirus. The Hammer and the Dance - Tomás Pueyo et al.",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Pierre-Olivier Gournichas",
                    slug: 'scribd',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Our World in Data - Coronavirus Disease (COVID-19)",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Niel M. Ferguson",
                    slug: 'pdf',
                    sources : [
                        {
                            title: "Prensa",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Impact of non-pharmaceutical interventions (NPIs) to reduce COVID-19 mortality and healthcare demand - Niel M Ferguson et al",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}



const awesomeList = {
    title: "Awesome List",
    slug: "awesome-list",
    sections: [
        {
            cards: [
                {
                    title: "Soroush Chehresa",
                    slug: "github",
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Soroush Chehresa - awesome-coronavirus",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Graphext",
                    slug: 'notion',
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "corona-data por Graphext - Coronalpanel",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Asteya Network",
                    slug: 'asteya-network',
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Asteya Network - Coronalpanel",
                                    link: "//"
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}

const monolidades = {
    title: "Monolidades",
    slug: "monolidades",
    sections: [
        {
            cards: [
                {
                    title: "Frena la curva",
                    slug: "frena-la-curva",
                    sources : [
                        {
                            title: "Dashboard",
                            links:  [
                                {
                                    icon: "link",
                                    name: "Plataforma colaborativa de ayuda a afectados por el coronavirus",
                                    link: "//"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}
  
  export const sourcesOfData = [
            official_data_sources_INT, 
            apis_repos,
            trackersInteractiveMaps,
            trackersCuriosities,
            predictiveModels,
            commercialTools,
            livefeedNews,
            newsArticles,
            academicArticles,
            awesomeList,
            monolidades
        ]
  