import React from "react"
import SourcesCCAADesktop from "./SourcesCCAADesktop"
import { officialDataCCAA } from "../../data/resourcesDataCCAA"
import SourcesCards from "./SourcesCards"
import { Box } from '@sqymagma/elements'

export default () => {
return (
    <>
        <Box display={["flex", "none"]}>
            <SourcesCards data={ officialDataCCAA } idx="1"/>
        </Box>
        <Box display={["none", "flex"]} width={1}>
            <SourcesCCAADesktop/>
        </Box>
    </>
)}

