import React, { useState, useEffect } from "react"
import { Box, Text, Flex } from "@sqymagma/elements"
import { theme } from "../../utils/themeGet"
import { MenuClose, ArrowRight, ArrowLeft } from "../IconSystem"
import Card from "./Card"
import { Transition, TransitionGroup } from "react-transition-group"



export default ({  card, isOpen, toggleCarousel, cards }) => {
    const  [ cardInView, setCardInView ] = useState(null)
    const [sense, setSense] = useState(1)

    useEffect ( () => {
        setCardInView(card)
    }, [card])

    const goPrevCard = _ => {
        const [currentCard] = cards.filter(el => el === cardInView)
        const currentCardIndex = cards.indexOf(currentCard)
        setCardInView(cards[currentCardIndex - 1 >= 0 ? currentCardIndex - 1 : cards.length - 1])
        setSense(-1)
    }

    const goNextCard = _ => {
        const [currentCard] = cards.filter(el => el === cardInView)
        const currentCardIndex = cards.indexOf(currentCard)
        setCardInView(cards[currentCardIndex + 1 <= cards.length - 1 ? currentCardIndex + 1 : 0])
        setSense(1)
    }

    const timeout = 500;

    const defaultStyle = {
        transition: `transform 200ms, opacity 200ms ease`,
        opacity: 1
    };

    const transitionStyles = {
        entering: { transform: `translateX(${(sense*-1) * 50}vw)`, opacity: 0 }, 
        entered: { transform: 'translateX(0)', opacity: 1},
        exiting: { opacity: 1 },
        exited: { opacity: 0 }
    };

    return (
        <Box
        position="absolute" width="100vw"
        top="0" right="0" bottom="0" left="0"
        z-index="-1"
        height="100%"
        css={`{
            z-index: ${isOpen ? "1" : "-1"};
            pointer-events: ${isOpen ? "all" : "none"};
            transition: all 0.5s ${theme(`easingFn.easeOut`)};
            opacity: ${isOpen ? "1" : "0"};
            transition-duration: ${isOpen ? ".25s" : ".25s"};
        }`}>
            <Box position="absolute" width="100%" height="100%" bg="brand01" opacity={0.8} zIndex="-1" />
            <Flex position="absolute" right="16px" top="16px" zIndex="2">
                <MenuClose fill="inverse04" height="16px" cursor="pointer" onClick={toggleCarousel} />
            </Flex>

            <Flex position="absolute" left="16px" top="50%" zIndex="2" css={`{ transform: translateY(-50%); }`}>
                <ArrowLeft fill="inverse04" height="24px" cursor="pointer" onClick={goPrevCard} />
            </Flex>

            <Flex position="absolute" right="16px" top="50%" zIndex="2" css={` { transform: translateY(-50%);}`}>
                <ArrowRight fill="inverse04" height="24px" cursor="pointer" onClick={goNextCard} />
            </Flex>

            {cardInView && (
                <TransitionGroup>
                    <Transition key={cardInView.isocode} timeout={timeout}>
                        {state => (
                        <Flex
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state]
                            }}
                            p="l" alignItems="center" justifyContent="center"
                            width={1} height="-webkit-fill-available"
                        >
                            <Box width="80%" maxWidth="560px" position="relative">
                                <Flex position="absolute" right="16px" top="16px" zIndex="2">
                                    <MenuClose fill="inverse04" height="16px" cursor="pointer" onClick={toggleCarousel} />
                                </Flex>
                                <Card title={cardInView.title} sources={cardInView.sources} slug={cardInView.isocode} mx="0">
                                    <Text as="h2" textStyle="l" color="text01">
                                    {cardInView.title}
                                    </Text>
                                </Card>
                            </Box>
                        </Flex>
                        )}
                    </Transition>
                </TransitionGroup>
            )}
        </Box>
    )
}
