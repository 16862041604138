import React from "react";
import { MaxWidth, Box, Text } from "@sqymagma/elements";
import Section from "../Section";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const imageData = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "overlay-dark-source-section.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64

          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData.fileName.childImageSharp.fluid}
      style={{
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height: "75vh"
      }}
      css={`
        background-color: ${(p) => p.theme.color.brand03};
      `}
    >
      <Section display="flex" alignItems="center" height="100vh">
        <MaxWidth>
          <Box mb="xl">
            <Text as="h1" color="inverse01" textStyle="tera">
              Recursos
            </Text>
          </Box>
        </MaxWidth>
      </Section>
    </BackgroundImage>
  );
};
