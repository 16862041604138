import React from "react"
import { theme } from "../../utils/themeGet"
import { Text } from "@sqymagma/elements"


export default ({ cards, handleClicked, handleMouseOver, handleMouseOut}) => {
   return( 
   <Text as="ul" css={`{  columns: 2; -webkit-columns: 2; -moz-columns: 2; }`}>
        {cards.map((ccaa, idx) => (
        <Text as="li" textStyle="l" idx={idx} color="brand03" mb="s">
            <Text
            as="span"
            onMouseOver={() => handleMouseOver(ccaa)}
            onMouseOut={() => handleMouseOut()}
            onFocus={() => handleMouseOver(ccaa)}
            onBlur={() => handleMouseOut()}
            onClick={() => handleClicked(ccaa)}
            css={`
                {
                cursor: pointer;
                transition: color ease 0.3s;
                &:hover {
                    color: ${theme("color.text06")};
                }
                }
            `}
            >
            {ccaa.name}
            </Text>
        </Text>
        ))}
    </Text>)
}