    import React from "react"
    import { MaxWidth, Box, Text, Columns, Column } from "@sqymagma/elements"
    import Section from "../Section"
    import Card from "./Card"

    export default ({ data, idx }) => {

        const getCardWidth = (arr) => {
            return  [1, null, 1/2, null, null, null, (arr.length <= 2 ? 1/2 : 1/3)]
        }


    return (

        <Section bg={idx%2 ? "brand03" : "darkBrand03"} py="xl">
            <MaxWidth>
                { data.title && <Text as="h1" textStyle="xxl" color="text06" mb="xs">{data.title}</Text>}
            <Box>
                {data.sections.map((section, idx) => (
                    <Box  mb="l" key={idx}>
                        <Box mb="m">
                            { section.title && <Text as="h3" textStyle="xl" color="text01">{section.title}</Text> }
                            { section.subtitle && <Text as="p" textStyle="l" color="text04" mb="xs">{section.subtitle}</Text> }
                        </Box>
                        <Columns width={1} mx="-24px" display="flex" alignItems="stretch">
                        {section.cards.map((card, i, arr) => {

                            return (
                            <Column width={getCardWidth(arr)} display="flex" mb="l" key={i}>
                                <Card
                                    dataSet = {data.slug}
                                    title={card.title}
                                    sources={card.sources}
                                    mx="m"
                                    {...card}
                                >
                                    <Text as="h2" textStyle="l" color="text01">{card.title}</Text>
                                </Card>
                            </Column>
                        )})}
                        </Columns>
                    </Box>
                ))}
                  
            </Box>
            </MaxWidth>
        </Section>
    )
    }
